import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@fontsource/inter";
import "@fontsource/inter/600.css";
import styled from "styled-components";

import {
  fetchComments,
  fetchProductItemDetails,
  fetchProductList,
  fetchProductPredictedUnits,
  fetchProductProfitability,
  fetchProductRanks,
  fetchProductSales,
  fetchProductUnitEconomics,
  fetchProductsLongTermStorageData,
  storeAnalyticsSelector,
} from "../../../../../redux/slices/store_analytics";
import tooltipContent from "../../../../components/tooltips_content";
import { sortAvailableType } from "../../constants";
import {
  FiltersValueTextField,
  StyledSelect,
} from "../StoreProductDetails/BusinessReportAdvanced/styles";
import { SortIcon } from "../StoreProducts/components";
import { handleSort } from "../StoreProducts/helpers";
import {
  ColumnTitle,
  EmptyProductListMsg,
  LastUpdatedText,
  TableStyled,
} from "../StoreProducts/styles";

import { ProductRowDetailed } from "./AllProductsTableRow";

const CustomCheckbox = styled(Checkbox)`
  & .MuiSvgIcon-root {
    font-size: 22px;
  }
`;

const CustomInput = styled(FormControlLabel)`
  margin-left: 5px;
  & .MuiFormControlLabel-label {
    font: 300 12px "Inter", sans-serif;
  }
`;

const TableCustom = ({ style, value }) => {
  // const [lastFetchedProduct, setLastFetchedProduct] = useState(null);

  var columns = [
    "All store Products in the last 30 days",
    // "FBA Quantity",
    // "3PL Quantity",
    // "Units on the way",
  ];

  const dispatch = useDispatch();

  const {
    productItemDetailsList,
    productItemDetailsLoading,
    productListData,
    productListLoading,
    productSalesList,
    productSalesLoading,
    productUnitEconomicsList,
    productUnitEconomicsLoading,
    productProfitabilityList,
    productProfitabilityLoading,
    productRanksList,
    productRanksLoading,
    productLongTermStorageData,
    productLongTermStorageDataLoading,
    productPredictedUnitsList,
    productPredictedUnitsLoading,
    productCommentsData,
  } = useSelector(storeAnalyticsSelector);

  const filterAttributes = [
    "Days of supply",
    "Unit economics",
    "Realistic stock",
    "Product velocity",
    "Velocity change",
  ];
  const filterActions = ["greater than", "less than", "equal to"];

  const [currStore, setCurrStore] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [openAllRows, setOpenAllRows] = React.useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const filterAttributeRef = useRef(null);
  const filterActionRef = useRef(null);
  const filterValueRef = useRef(null);
  const [filterFieldsResetFlag, setFilterFieldsResetFlag] = useState(false);
  const [filterErrors, setFilterErrors] = useState({
    filterAttribute: false,
    filterAction: false,
    filterValue: false,
  });

  const showLastUpdateDate = (e) => {
    let columnName = null;
    if (productsList.length > 0) {
      if (e === "FBA Quantity") {
        columnName = "fba_report";
      } else if (e === "3PL Quantity") {
        columnName = "3pl";
      } else if (e === "Units on the way") {
        columnName = "velocity";
      }
    }
    let lastUpdated = null;
    if (columnName) {
      lastUpdated =
        "Last updated on " + productsList[0][columnName]?.last_updated;
    }
    return lastUpdated;
  };

  useEffect(() => {
    if (value.store !== "all" && value.store !== currStore) {
      console.log("dispatching only once for store " + value.store);
      dispatch(
        fetchProductList({
          store: value.store,
          duration: value.compareConditions.current,
          hierarchy: "hierarchy",
        })
      );
      dispatch(
        fetchProductItemDetails({
          store: value.store,
          level: "hirearchy",
        })
      );
      dispatch(
        fetchProductSales({
          store: value.store,
          level: "child",
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );
      dispatch(
        fetchProductRanks({
          store: value.store,
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );
      dispatch(
        fetchProductUnitEconomics({
          store: value.store,
          level: "child",
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );
      dispatch(
        fetchProductProfitability({
          store: value.store,
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );
      dispatch(
        fetchProductsLongTermStorageData({
          store: value.store,
        })
      );
      dispatch(
        fetchProductPredictedUnits({
          store: value.store,
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );
      dispatch(
        fetchComments({
          store: value.store,
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );
      setCurrStore(value.store);
    }
  }, [value, currStore, dispatch]); // table rerender on compare conditions change

  useEffect(() => {
    if (Object.keys(productItemDetailsList).length > 0) {
      setProductsList(productItemDetailsList);
    } else {
      setProductsList([]);
    }
  }, [productItemDetailsList]);

  const [sortBy, setSortBy] = useState("1 Month Forecast");
  const [sortType, setSortType] = useState(sortAvailableType);

  const relevantProductData = (product) => {
    if (!product.children) {
      return (
        productListData &&
        product.sku.split(",").map((sku) => {
          return { sku: sku.trim(), ...productListData[sku.trim()] };
        })
      );
    } else {
      return (
        productListData &&
        Object.entries(productListData)
          .map(([key, value]) => {
            if (
              product.children.map((c) => c.child_asin).includes(value.asin)
            ) {
              return { ...value, sku: key };
            } else {
              return null;
            }
          })
          .filter(Boolean)
      );
    }
  };

  const relevantCommentsData = (product) => {
    let emptyCommentsData = {
      [product.child_asin]: [],
    };
    if (!productCommentsData) {
      return emptyCommentsData;
    }
    let parentChildAsins = [];
    if (product.children) {
      parentChildAsins = product.children.map((c) => c.child_asin);
      parentChildAsins.push(product.parent_asin);
    } else {
      parentChildAsins.push(product.child_asin);
    }

    return Object.fromEntries(
      Object.entries(productCommentsData).filter(([key]) =>
        parentChildAsins.includes(key)
      )
    );
  };

  const relevantPredictedUnitsData = (product) => {
    let emptyPredictedUnitsData = {
      [product.child_asin]: {},
      dates: [],
    };
    if (!productPredictedUnitsList) {
      return emptyPredictedUnitsData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    return Object.entries(productPredictedUnitsList)
      .filter(([key]) => childAsins.includes(key) || key === "dates")
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyPredictedUnitsData);
  };

  const relevantRanksData = (product) => {
    let emptyRanksData = {
      [product.child_asin]: {},
    };
    if (!productRanksList) {
      return emptyRanksData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    // return productSalesList.filter((item) => item.asin === asin);
    return Object.entries(productRanksList)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyRanksData);
  };

  const relevantProfitabilityData = (product) => {
    let emptyProfitabilityData = {
      [product.child_asin]: {},
    };
    if (!productProfitabilityList) {
      return emptyProfitabilityData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    // return productSalesList.filter((item) => item.asin === asin);
    return Object.entries(productProfitabilityList)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyProfitabilityData);
  };

  const relevantSalesData = (product) => {
    let emptySalesData = {
      [product.child_asin]: { dates: [], units: [], avg_price: [], sales: [] },
    };
    if (!productSalesList) {
      return emptySalesData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    // return productSalesList.filter((item) => item.asin === asin);
    return Object.entries(productSalesList)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptySalesData);
  };

  const relevantEconomicsData = (product) => {
    let emptyEconomicsData = {
      [product.child_asin]: {
        date: "",
        price: 0,
        cogs: 0,
        fulfillment_fee: 0,
        referal_fee: 0,
      },
    };
    if (!productUnitEconomicsList) {
      return emptyEconomicsData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    return Object.entries(productUnitEconomicsList)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyEconomicsData);
  };

  const relevantLongTermStorageData = (product) => {
    let emptyLongTermStorageData = {};
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    return Object.entries(productLongTermStorageData)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyLongTermStorageData);
  };

  const isDataLoading =
    productItemDetailsLoading ||
    productListLoading ||
    productSalesLoading ||
    productUnitEconomicsLoading ||
    productProfitabilityLoading ||
    productLongTermStorageDataLoading ||
    productRanksLoading ||
    productPredictedUnitsLoading;

  const resetFilterFields = () => {
    filterAttributeRef.current.value = "";
    filterActionRef.current.value = "";
    filterValueRef.current.value = "";
    setFilterFieldsResetFlag(!filterFieldsResetFlag);
    setFilterErrors({
      filterAttribute: false,
      filterAction: false,
      filterValue: false,
    });
  };

  const addFilter = () => {
    const filterFieldsErrors = {
      filterAttribute: !filterAttributeRef.current.value,
      filterAction: !filterActionRef.current.value,
      filterValue: !filterValueRef.current.value,
    };
    if (
      filterFieldsErrors.filterAttribute ||
      filterFieldsErrors.filterAction ||
      filterFieldsErrors.filterValue
    ) {
      setFilterErrors(filterFieldsErrors);
      return;
    }

    setOpenAllRows(true);
    const newFilter = {
      attr: filterAttributeRef.current.value,
      action: filterActionRef.current.value,
      value: parseFloat(filterValueRef.current.value),
    };
    setAppliedFilters([...appliedFilters, newFilter]);
    resetFilterFields();
  };

  const removeFilter = (indexToRemove) => {
    setAppliedFilters(
      appliedFilters.filter((_, index) => index !== indexToRemove)
    );
    setFilterErrors({
      filterAttribute: false,
      filterAction: false,
      filterValue: false,
    });
  };

  return (
    <React.Fragment>
      <div style={{ "margin-bottom": "10px" }}>
        <CustomInput
          control={
            <CustomCheckbox
              checked={openAllRows}
              onChange={(e) => setOpenAllRows(e.target.checked)}
            />
          }
          label={"Expand all products"}
        />
        <FormControl error={filterErrors.filterAttribute}>
          <StyledSelect
            ref={filterAttributeRef}
            disabled={isDataLoading || currStore.length === 0}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return "Select Attribue";
              }
              return selected;
            }}
            inputProps={{ ref: filterAttributeRef }}
            key={`attribute-${filterFieldsResetFlag}`}
            error={filterErrors.filterAttribute}
          >
            {filterAttributes.map((attr, index) => (
              <MenuItem key={index} value={attr}>
                {attr}
              </MenuItem>
            ))}
          </StyledSelect>
          {filterErrors.filterAttribute && (
            <FormHelperText style={{ color: "#d32f2f" }}>
              This field is required
            </FormHelperText>
          )}
        </FormControl>
        <FormControl error={filterErrors.filterAction}>
          <StyledSelect
            ref={filterActionRef}
            disabled={isDataLoading || currStore.length === 0}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return "Select Action";
              }
              return selected;
            }}
            inputProps={{ ref: filterActionRef }}
            key={`action-${filterFieldsResetFlag}`}
            error={filterErrors.filterAction}
          >
            {filterActions.map((action, index) => (
              <MenuItem key={index} value={action}>
                {action}
              </MenuItem>
            ))}
          </StyledSelect>
          {filterErrors.filterAction && (
            <FormHelperText style={{ color: "#d32f2f" }}>
              This field is required
            </FormHelperText>
          )}
        </FormControl>
        <FormControl error={filterErrors.filterValue} style={{ width: "10%" }}>
          <FiltersValueTextField
            label="Enter Value"
            variant="outlined"
            size="small"
            disabled={isDataLoading || currStore.length === 0}
            inputProps={{ ref: filterValueRef }}
            key={`value-${filterFieldsResetFlag}`}
            error={filterErrors.filterValue}
          />
          {filterErrors.filterValue && (
            <FormHelperText style={{ color: "#d32f2f" }}>
              This field is required
            </FormHelperText>
          )}
        </FormControl>
        <Button
          style={{ "margin-left": "10px" }}
          onClick={addFilter}
          disabled={isDataLoading || currStore.length === 0}
        >
          Add filter
        </Button>
      </div>
      {appliedFilters.length > 0 && (
        <div style={{ "margin-left": "16px", "margin-top": "10px" }}>
          Currently applied filters:
          <ul>
            {appliedFilters.map((filter, index) => {
              return (
                <li key={index}>
                  <div>
                    {filter.attr} {filter.action} {filter.value}
                    <Button onClick={() => removeFilter(index)}>X</Button>
                  </div>
                  {appliedFilters.length > 1 &&
                    index !== appliedFilters.length - 1 &&
                    "and"}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <TableStyled style={style} component={Paper}>
        {isDataLoading ? (
          <>
            {[1, 2, 3, 4, 5].map((item) => (
              <Skeleton
                key={item}
                width={"97%"}
                sx={{ mt: 5, ml: 5 }}
                variant="rectangular"
                height={15}
              />
            ))}
          </>
        ) : (
          <Table>
            {/* table headers */}
            <TableHead>
              <TableRow>
                {columns.map((e, i) => (
                  <TableCell
                    key={i}
                    style={{
                      padding: "13px",
                      whiteSpace: "nowrap",
                      borderLeft:
                        e === "Conversion Rate" && "1px solid #e0e0e0",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Inter",
                        fontWeight: 600,
                        color: "#64748B",
                        position: "relative",
                      }}
                      data-testid="table_header"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {sortBy === e && <SortIcon sortType={sortType[e]} />}
                        <ColumnTitle
                          onClick={() =>
                            handleSort({
                              columnTitle: e,
                              productsList,
                              setProductsList,
                              setSortBy,
                              sortType: sortType[e],
                              sortTypeState: sortType,
                              setSortType,
                            })
                          }
                        >
                          {e}
                        </ColumnTitle>
                        {e !== "Product" && (
                          <Tooltip
                            placement="right"
                            arrow
                            title={
                              <Box
                                sx={{
                                  fontFamily: "Inter",
                                  fontWeight: 400,
                                  fontSize: 12,
                                  width: "185px",
                                }}
                                data-testid="2nd_3rd_column_tooltip_menu"
                              >
                                <Typography>
                                  {tooltipContent.store_management_product_list.hasOwnProperty(
                                    e
                                  ) &&
                                    tooltipContent
                                      .store_management_product_list[e]}
                                </Typography>
                              </Box>
                            }
                          >
                            <InfoIcon
                              sx={{
                                width: "15px",
                                height: "15px",
                                color: "#CBD5E1",
                                // marginRight: "20px",
                              }}
                              data-testid="2nd_3rd_columns_tooltip"
                            />
                          </Tooltip>
                        )}
                      </div>
                      <LastUpdatedText>
                        {showLastUpdateDate(e) ? showLastUpdateDate(e) : <br />}
                      </LastUpdatedText>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {/* table body products - productListData */}
            {productsList.length !== 0 ? (
              <TableBody>
                {productsList.map((product, index) => (
                  <ProductRowDetailed
                    key={index}
                    store={value.store}
                    sku={product.sku}
                    product={product}
                    compareConditions={value.compareConditions}
                    leaf={value.periodicity}
                    openAllRows={openAllRows}
                    inventoryData={relevantProductData(product)}
                    salesData={relevantSalesData(product)}
                    unitEconomicsData={relevantEconomicsData(product)}
                    profitabilityData={relevantProfitabilityData(product)}
                    ranksData={relevantRanksData(product)}
                    longTermStorageData={relevantLongTermStorageData(product)}
                    predictedUnitsData={relevantPredictedUnitsData(product)}
                    comments={relevantCommentsData(product)}
                    filters={appliedFilters}
                    setParentShow={null}
                    parentApiRef={null}
                    parentScrollHandler={null}
                  />
                ))}
              </TableBody>
            ) : null}
          </Table>
        )}
        {!productItemDetailsLoading && productItemDetailsList.length === 0 && (
          <EmptyProductListMsg>
            {value === "all"
              ? "Please select a store first!"
              : "No products available"}
          </EmptyProductListMsg>
        )}
      </TableStyled>
    </React.Fragment>
  );
};

export default TableCustom;
