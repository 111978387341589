import TextField from "@mui/material/TextField";
import React, { useState } from "react";

const UnitEconomics = ({
  unitEconomicsData,
  ppcPerUnit,
  initialPrice,
  onCalculationComplete,
}) => {
  const unitEconomicsBreakdown = (unitEconomicsData, inputPrice = 0) => {
    let price = inputPrice;
    let cogs = 0;
    let fulfillment = 0;
    let referral = 0;
    let storage = 0;
    let hasChildren = false;
    if (unitEconomicsData) {
      if (Object.keys(unitEconomicsData).length === 1) {
        //parent no children
        unitEconomicsData = Object.values(unitEconomicsData)[0];
      }
      if ("price" in unitEconomicsData) {
        //child
        if (unitEconomicsData) {
          if (price === 0) {
            price = unitEconomicsData.price;
            referral = unitEconomicsData.referal_fee;
          } else {
            referral = (price * 0.15).toFixed(2);
          }
          cogs = unitEconomicsData.cogs;
          fulfillment = unitEconomicsData.fulfillment_fee;
          storage = unitEconomicsData.storage_fee;
        }
      } else {
        //parent
        hasChildren = Object.values(unitEconomicsData).length > 1;
        Object.values(unitEconomicsData).forEach((value) => {
          const [p, c, f, r, s, _] = unitEconomicsBreakdown(value);
          price += p;
          cogs += c;
          fulfillment += f;
          referral += r;
          storage += s;
        });
        price = (price / Object.keys(unitEconomicsData).length).toFixed(2);
        cogs = (cogs / Object.keys(unitEconomicsData).length).toFixed(2);
        fulfillment = (
          fulfillment / Object.keys(unitEconomicsData).length
        ).toFixed(2);
        referral = (referral / Object.keys(unitEconomicsData).length).toFixed(
          2
        );
        storage = (storage / Object.keys(unitEconomicsData).length).toFixed(2);
      }
    }
    return [
      price,
      cogs,
      Number(fulfillment),
      Number(referral),
      Number(storage),
      hasChildren,
    ];
  };

  // Initial breakdown
  const initialBreakdown = unitEconomicsBreakdown(
    unitEconomicsData,
    initialPrice
  );

  // State variables for each piece of data
  const [price, setPrice] = useState(initialBreakdown[0]);
  const [cogs, setCogs] = useState(initialBreakdown[1]);
  const [fulfillment, setFulfillment] = useState(initialBreakdown[2]);
  const [referral, setReferral] = useState(initialBreakdown[3]);
  const [storage, setStorage] = useState(initialBreakdown[4]);
  const [hasChildren, setHasChildren] = useState(initialBreakdown[5]);

  const [ppcpu, setPpcpu] = useState(ppcPerUnit);

  const calcUnitEconomics = (unitEconomicsData) => {
    let profit = 0;
    if (unitEconomicsData) {
      if ("price" in unitEconomicsData) {
        if (unitEconomicsData) {
          profit = price - cogs - fulfillment - referral - storage - ppcpu;
        }
      } else {
        Object.entries(unitEconomicsData).forEach(([_, value]) => {
          profit += calcUnitEconomics(value);
        });
        profit = profit / Object.keys(unitEconomicsData).length;
      }
    }
    onCalculationComplete(
      parseFloat(profit.toFixed(1).replace(/\.0$/, ""), 10)
    );
    return profit;
  };

  // Handle change event
  const handlePriceChange = (e) => {
    const [
      newPrice,
      newCogs,
      newFulfillment,
      newReferral,
      newStorage,
      newHasChildren,
    ] = unitEconomicsBreakdown(unitEconomicsData, Number(e.target.value));

    // Update state
    setPrice(newPrice);
    setCogs(newCogs);
    setFulfillment(newFulfillment);
    setReferral(newReferral);
    setStorage(newStorage);
    setHasChildren(newHasChildren);
  };

  const handlePpcChange = (e) => {
    if (e.target.value !== "") {
      setPpcpu(Number(e.target.value));
    } else {
      setPpcpu(ppcPerUnit);
    }
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            fontFamily: "Inter",
            fontWeight: 600,
            color: "black",
            fontSize: 24,
            textAlign: "left",
          }}
        >
          ${calcUnitEconomics(unitEconomicsData).toFixed(1).replace(/\.0$/, "")}
        </div>
        <div style={{ fontSize: 12, marginLeft: 3 }}>
          {"(" +
            ((calcUnitEconomics(unitEconomicsData) / price) * 100).toFixed(1) +
            "%)"}
        </div>
      </div>
      <div>
        {hasChildren ? "avg " : ""}price: $
        {hasChildren ? (
          price
        ) : (
          <TextField
            sx={{
              width: "60px",
              marginTop: "-10px",
              marginLeft: "5px",
            }}
            size="small"
            id="outlined-basic"
            placeholder={price != null ? price.toString() : ""}
            variant="outlined"
            onChange={handlePriceChange}
            InputProps={{
              style: { height: "20px", fontSize: "0.6rem", marginTop: "8px" },
            }}
            inputProps={{
              style: { height: "20px", fontSize: "0.6rem" },
            }}
          />
        )}
        <br />
        {hasChildren ? "avg " : ""}cogs: -${cogs}
        <br />
        {hasChildren ? "avg " : ""}fulfillment: -${fulfillment}
        <br />
        {hasChildren ? "avg " : ""}referral: -${referral}
        <br />
        {hasChildren ? "avg " : ""}storage: -${storage}
        <br />
        {hasChildren ? "avg " : ""}PPC per unit: -$
        {hasChildren ? (
          ppcPerUnit
        ) : (
          <TextField
            sx={{
              width: "60px",
              marginTop: "-10px",
              marginLeft: "5px",
            }}
            size="small"
            id="outlined-basic"
            placeholder={ppcPerUnit.toString()}
            variant="outlined"
            onChange={handlePpcChange}
            InputProps={{
              style: { height: "20px", fontSize: "0.6rem", marginTop: "8px" },
            }}
            inputProps={{
              style: { height: "20px", fontSize: "0.6rem" },
            }}
          />
        )}
      </div>
    </div>
  );
};

export { UnitEconomics };
